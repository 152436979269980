<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Detalle de ingreso</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <div class="mb-5" v-if="input.observation">
          <h6>Observación</h6>
          <p class="mt-2">{{ input.observation }}</p>
        </div>
        <div>
          <h6>Productos</h6>
          <vs-table class="mt-5" :data="input.products">

            <template slot="thead">
              <vs-th>Descripción</vs-th>
              <vs-th>Nro. de Parte</vs-th>
              <vs-th>Cantidad</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                <vs-td :data="tr.description">
                  <p>{{ tr.description }}</p>
                </vs-td>

                <vs-td :data="tr.numberPart">
                  <p>{{ tr.numberPart }}</p>
                </vs-td>

                <vs-td :data="tr.quantity">
                  <p>{{ tr.quantity }}</p>
                </vs-td>

              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </component>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'

export default {
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar
  },
  mixins: [trimString],
  data () {
    return {
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      input: {
        products: []
      },
      progress: false
    }
  },
  watch: {
    isSidebarActive (val) {
      if (!val) return
      this.input = {
        ...this.data
      }
      this.$validator.reset()
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 850px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
